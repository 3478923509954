<template>
  <div>
    <!-- /.modal -->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div
        class="modal-dialog"
        role="document"
        style="max-width: 794px; max-height: 288px"
      >
        <form @submit.prevent="save" class="card">
          <div class="modal-content">
            <div class="modal-header bg-primary">
              <h5 class="modal-title" id="exampleModalLabel">
                <i class="fa fa-plus"></i>
                {{ form.id ? "Edit Merchant" : "Add Merchant" }}
              </h5>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-sm-3">
                  <!-- <div
                    class="form-group"
                    v-if="error && !form.image"
                    id="div_image"
                    @click="defaultBtnImage()"
                    style="cursor: pointer; border: 1px solid red"
                  >
                    <img
                      :src="form.image"
                      v-if="form.image"
                      width="100%"
                      height="100%"
                      class="img-thumbnail"
                    />
                    <img
                      src="@assets/no-image.png"
                      v-else
                      width="100%"
                      height="100%"
                      class="img-thumbnail"
                      alt="No Image"
                    />
                    <input
                      type="file"
                      accept="image/x-png,image/jpeg"
                      id="default-btn"
                      @change="handleImage"
                      :disabled="loading"
                      hidden
                    />
                    <div class="bg-primary text-center p-2">
                      Upload Photo<span class="text-red">*</span>
                    </div>
                    <div><span class="text-red">*</span> Photo max 5MB</div>
                    <span class="text-red">*</span> File Photo JPG or PNG -->
                    <!-- <span v-if="error && !form.image" style="color: red;">*{{ error }}</span> -->
                  <!-- </div> -->
                  <div
                    class="form-group"
                    id="div_image"
                    @click="defaultBtnImage()"
                    style="cursor: pointer"
                  >
                    <img
                      :src="form.image"
                      v-if="form.image"
                      width="100%"
                      height="100%"
                      class="img-thumbnail"
                    />
                    <img
                      src="@assets/no-image.png"
                      v-else
                      width="100%"
                      height="100%"
                      class="img-thumbnail"
                      alt="No Image"
                      style="border: 1px solid red"
                    />
                    <input
                      type="file"
                      accept="image/x-png,image/jpeg"
                      id="default-btn"
                      @change="handleImage"
                      :disabled="loading"
                      hidden
                    />
                    <div class="bg-primary text-center p-2">
                      Upload Photo<span class="text-red">*</span>
                    </div>
                    <div><span class="text-red">*</span> Photo max {{ maxSizeText }}</div>
                    <span class="text-red">*</span> File Photo JPG or PNG
                    <!-- <span v-if="error && !form.image" style="color: red;">*{{ error }}</span> -->
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="row">
                    <span style="font-weight: 700">Data Merchant</span>
                    <div class="form-group">
                      <label for="recipient-name" class="col-form-label"
                        >Merchant Name<span class="text-red">*</span></label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="recipient-name"
                        required
                        v-model="form.name"
                        :disabled="loading"
                      />
                    </div>
                    <div class="form-group">
                      <label for="recipient-email" class="col-form-label"
                        >Email<span class="text-red">*</span></label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="recipient-email"
                        required
                        v-model="form.email"
                        :disabled="loading"
                      />
                    </div>
                    <div class="form-group">
                      <label for="recipient-phone-number" class="col-form-label"
                        >Nomor Telepon<span class="text-red">*</span></label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="recipient-phone-number"
                        required
                        v-model="form.phone_number"
                        :disabled="loading"
                      />
                    </div>
                    <div class="form-group">
                      <label for="recipient-bank-name" class="col-form-label"
                        >Nama Bank<span class="text-red">*</span></label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="recipient-bank-name"
                        required
                        v-model="form.bank_name"
                        :disabled="loading"
                      />
                    </div>
                    <div class="form-group">
                      <label for="recipient-account-name" class="col-form-label"
                        >Nama Rekening<span class="text-red">*</span></label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="recipient-account-name"
                        required
                        v-model="form.account_name"
                        :disabled="loading"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="row" style="margin-top: 21px">
                    <div class="form-group">
                      <label for="recipient-pic-name" class="col-form-label"
                        >Nama PIC<span class="text-red">*</span></label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="recipient-pic-name"
                        required
                        v-model="form.pic_name"
                        :disabled="loading"
                      />
                    </div>
                    <div class="form-group">
                      <label for="recipient-password" class="col-form-label"
                        >Password<span class="text-red">*</span></label
                      >
                      <input
                        type="password"
                        class="form-control"
                        id="recipient-password"
                        required
                        v-model="form.password"
                        :disabled="loading"
                      />
                    </div>
                    <div class="form-group">
                      <label for="recipient-npwp" class="col-form-label"
                        >Nomor NPWP</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="recipient-npwp"
                        v-model="form.npwp"
                        :disabled="loading"
                      />
                    </div>
                    <div class="form-group">
                      <label
                        for="recipient-account_number"
                        class="col-form-label"
                        >Nomor Rekening<span class="text-red">*</span></label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="recipient-account_number"
                        required
                        v-model="form.account_number"
                        :disabled="loading"
                      />
                    </div>
                  </div>
                </div>
                <!-- <div class="form-group">
                                <label class="text-secondary"><span class="text-red">*</span>Photo max 5mb</label>
                                <label class="text-secondary"><span class="text-red">*</span>File Photo JPEG, JPG, and PNG</label>
                            </div> -->
              </div>
              <!-- </div> -->
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
                @click="resetMerchant"
              >
                Close
              </button>
              <button type="submit" class="btn btn-primary" :disabled="loading">
                <i class="fas fa-spinner fa-spin" v-if="loading"></i>
                {{ loading ? "Saving ..." : "Save" }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>  

    <div class="row mb-2">
      <div class="col-sm-6">
        <h1 class="m-0 text-dark capitalize">Merchant</h1>
      </div>
      <!-- /.col -->
      <div class="col-sm-6 d-flex justify-content-end">
        <button type="button" class="btn btn-primary btn-lg" @click="showModal">
          <span class="fas fa-plus"> </span> Add Merchant
        </button>
      </div>
      <!-- /.col -->
    </div>

    <data-tables-master-data
      :config="config"
      id="sampleTable"
    ></data-tables-master-data>

    <payment-gateway-setting
      v-if="merchant !== null"
      :merchant="merchant"
      :paymentGatewayConfig="paymentGatewayConfig"
      :close="clearDataMerchant"
      :savePaymentSetting="savePaymentSetting"
      :formPayout="formPayout"
    ></payment-gateway-setting>
  </div>
</template>

<script>
import DataTablesMasterData from "@components/DataTablesMasterData.vue";
import PaymentGatewaySetting from "@components/PaymentGatewaySetting.vue";
import _ from "lodash";

export default {
  components: {
    DataTablesMasterData,
    PaymentGatewaySetting,
  },

  data() {
    return {
      active: false,
      uploadFieldName: "file",
      loading: false,
      maxSize: 1024,
      maxSizeText: "1 MB",
      formId: null,
      paymentGateway: [],
      merchant: null,
      form: {
        id: null,
        image: "",
        name: "",
        email: "",
        phone_number: "",
        bank_name: "",
        account_name: "",
        pic_name: "",
        password: "",
        npwp: "",
        account_number: "",
      },
      formPayout: {
        id: null,
        api_key: "",
        api_key_2: "",
          secret_key: "",
        callback_token: "",
          no_merchant_id: "",
        tenant_name: "",
        tenant_code: "",
        username: "",
        password: "",
          partner_id: "",
          sub_partner_id: "",
        store_id: "",
        pos_id: "",
        project_id: "",
        collection_name: "",
        private_key_json: "",
        private_key_base64: "",
        payout_method: {
          id: "",
        },
        merchant: {
          id: "",
        },
      },
      merchantId: "",
      disabled: false,
      config: {
        columns: [
          {
            title: "Merchant ID",
            data: "id",
            searchable: false
          },
          {
            title: "Merchant Name",
            data: "name",
          },
          {
            title: "Vending Active",
            data: "active",
            searchable: false
          },
          {
            title: "Total Vending",
            data: "vending",
            render: (value) => {
              return value.length;
            },
            searchable: false
          },
          {
            title: "Total Revenue",
            data: "value",
            render: (val) => {
              return `Rp. ${this.formatPrice(val)}`;
            },
            searchable: false
          },
          {
            title: "Action",
            data: "id",
            searchable: false,
            render: (val) => {
              return `
              <div> 
                  <a 
                      href="javascript:void(0);" 
                      data-id="${val}" 
                      class="editor-payment-gateway" 
                      data-action="payment-gateway" 
                      data-toggle="tooltip" 
                      data-placement="top" 
                      title="Payment Gateway Setting"
                      style="color:#9E9E9E; margin-right:10px;"
                  >
                      <i class="fa fa-credit-card"></i>
                  </a>
                  <a 
                      href="javascript:void(0);" 
                      data-id="${val}" 
                      class="editor-edit" 
                      data-action="edit" 
                      data-toggle="tooltip" 
                      data-placement="top" 
                      title="Click to edit"
                      style="color:#9E9E9E; margin-right:10px;"
                  >
                      <i class="fa fa-edit"></i>
                  </a>
                  <a 
                      href="javascript:void(0);" 
                      data-id="${val}" 
                      class="editor-detail" 
                      data-action="detail" 
                      data-toggle="tooltip" 
                      data-placement="top" 
                      title="Click to detail"
                      style="color:#3267E3;"
                  >
                      <i class="fa fa-arrow-right"></i>
                  </a>
              </div>`;
            },
          },
        ],
        drawCallback: (settings) => {
          $(".editor-payment-gateway").on("click", (e) => {
            this.getDataMerchant($(e.target).closest("a").data("id"));
          });

          $(".editor-edit").on("click", (e) => {
            this.getEdit($(e.target).closest("a").data("id"));
          });

          $(".editor-detail").on("click", (e) => {
            this.clickRow($(e.target).closest("a").data("id"));
          });
        },
        url: this.Api.base + "/merchant",
        order: [[0, "desc"]],
        filter: []
      },
    };
  },
  mounted() {
    // this.getDataPaymentGateway();
    this.initData();
  },
  methods: {
    add() {},
    clickRow(id) {
      if (id) {
        this.$router.push({ name: "MerchantDetail", params: { id: id } });
      }
    },
    showModal() {
      this.resetMerchant();
      $("#exampleModal").modal("show");
    },
    getEdit(id) {
      if (!_.isNull(id)) {
        this.Api.get(`/merchant/${id}`)
          .then((res) => {
            if (res.data) {
              this.form.id = res.data.id;
              this.form.name = res.data.name;
              this.form.image = res.data.image;
              this.form.email = res.data.email;
              this.form.phone_number = res.data.phone_number;
              this.form.bank_name = res.data.bank_name;
              this.form.account_number = res.data.account_number;
              this.form.pic_name = res.data.pic_name;
              this.form.password = res.data.password;
              this.form.npwp = res.data.npwp;
              this.form.account_name = res.data.account_name;
              this.isDetail = false;
              $("#exampleModal").modal("show");
            }
          })
          .catch((e) => {
            window.console.log(e);
          });
      }
    },
    resetMerchant() {
      this.form = {
        id: null,
        image: "",
        name: "",
        email: "",
        phone_number: "",
        bank_name: "",
        account_name: "",
        pic_name: "",
        password: "",
        npwp: "",
        account_number: "",
      };
    },
    clearDataMerchant() {
      (this.merchant = null),
        (this.formPayout = {
          id: null,
          api_key: "",
          api_key_2: "",
            secret_key: "",
          callback_token: "",
            no_merchant_id: "",
          tenant_name: "",
          tenant_code: "",
          username: "",
          password: "",
            partner_id: "",
            sub_partner_id: "",
          store_id: "",
          pos_id: "",
          project_id: "",
          collection_name: "",
          private_key_json: "",
          private_key_base64: "",
          payout_method: {
            id: "",
          },
          merchant: {
            id: "",
          },
        });
    },
    getDataMerchant(id) {
      this.merchantId = id;
      if (this.merchantId) {
        this.Api.get(`/merchant/${this.merchantId}`)
          .then((res) => {
            this.merchant = { ...res.data };
            if (res.data.payout_detail) {
              this.formPayout.id = res.data.payout_detail.id;
              this.formPayout.api_key = res.data.payout_detail.api_key;
              this.formPayout.api_key_2 = res.data.payout_detail.api_key_2;
                this.formPayout.secret_key = res.data.payout_detail.secret_key;
              this.formPayout.callback_token =
                res.data.payout_detail.callback_token;
                this.formPayout.no_merchant_id =
                    res.data.payout_detail.no_merchant_id;
                this.formPayout.tenant_name = res.data.payout_detail.tenant_name;
                this.formPayout.tenant_code = res.data.payout_detail.tenant_code;
              (this.formPayout.username = res.data.payout_detail.username),
                (this.formPayout.password = res.data.payout_detail.password),
                  (this.formPayout.partner_id = res.data.payout_detail.partner_id),
                  (this.formPayout.sub_partner_id = res.data.payout_detail.sub_partner_id),
                (this.formPayout.store_id = res.data.payout_detail.store_id),
                (this.formPayout.pos_id = res.data.payout_detail.pos_id),
                (this.formPayout.project_id = res.data.payout_detail.project_id),
                (this.formPayout.collection_name = res.data.payout_detail.collection_name),
                (this.formPayout.private_key_json = res.data.payout_detail.private_key_json),
                (this.formPayout.private_key_base64 = res.data.payout_detail.private_key_base64),
                (this.formPayout.payout_method =
                  res.data.payout_detail.payout_method);
              this.formPayout.merchant = res.data.payout_detail.merchant;
            }
            $("#payoutSettingModal").modal("show");
          })
          .catch((e) => {});
      }
    },
    initData() {
      this.paymentGatewayConfig = {
        ajax: {
          url: env.api.base + "/payoutmethod?sort=id",
          data: (params) => {
            var filters = [["name", "like", params.term]];
            var query = {
              sort: "name",
              filters: params.term ? JSON.stringify(filters) : "",
              page: params.page ? params.page : 1,
            };

            return query;
          },
          processResults: (data, params) => {
            data.content.forEach((item) => {
              item.text = item.name;
            });
            return {
              results: data.content,
              pagination: {
                more: data.last == false,
              },
            };
          },
        },
      };
    },
    defaultBtnImage() {
      const defaultbtn = document.querySelector("#default-btn");
      defaultbtn.click();
    },
    handleImage(e) {
      const selectedImage = e.target.files[0];
      if (
        selectedImage.type === "image/png" ||
        selectedImage.type === "image/jpeg"
      ) {
        if (selectedImage.size <= this.maxSize * this.maxSize) {
          const reader = new FileReader();
          reader.onload = (e) => {
            this.form.image = e.target.result;
          };
          reader.readAsDataURL(selectedImage);
        } else {
          let errorTitle = "Submit Failed";
          let errorMessage = `File too big (> ${this.maxSizeText})`;
          this.showErrorAlert(errorTitle, errorMessage);  
        }
      } else {
        let errorTitle = "Submit Failed";
        let errorMessage = `File is not supported, please use JPG or PNG format`;
        this.showErrorAlert(errorTitle, errorMessage); 
      }
    },
    save() {
      this.loading = true;
      if (!_.isNull(this.form.id)) {
        this.Api.put(`/merchant/${this.form.id}`, this.form)
          .then((res) => {
            this.loading = false;
            this.refresh();
            this.resetMerchant();
            $("#exampleModal").modal("hide");

            let successTitle = "Submit Success";
            let successMessage = "Data merchant tersimpan";
            this.showSuccessAlert(successTitle, successMessage);
          })
          .catch((e) => {
            this.loading = false;
            
            let errorTitle = "Submit Failed";
            let errorMessage = e.response?.data?.message;
            this.showErrorAlert(errorTitle, errorMessage);
          });
      } else {
        this.Api.post("/merchant", this.form)
          .then((res) => {
            this.loading = false;
            this.refresh();
            this.resetMerchant();
            $("#exampleModal").modal("hide");
            
            let successTitle = "Submit Success";
            let successMessage = "Data merchant tersimpan";
            this.showSuccessAlert(successTitle, successMessage);
          })
          .catch((e) => {
            this.loading = false;

            let errorTitle = "Submit Failed";
            let errorMessage = e.response?.data?.message;
            this.showErrorAlert(errorTitle, errorMessage);
          });
      }
    },
    refresh() {
      this.config = {
        url: this.Api.base + "/merchant",
      };
    },
    formatPrice(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    savePaymentSetting() {
      this.loading = true;

      if (!_.isNull(this.formPayout.id)) {
        this.Api.put(`/payoutdetail/${this.formPayout.id}`, this.formPayout)
          .then((res) => {
            this.loading = false;
            this.refresh();
            this.clearDataMerchant();
            $("#payoutSettingModal").modal("hide");

            let successTitle = "Submit Success";
            let successMessage = "Data payment setting tersimpan";
            this.showSuccessAlert(successTitle, successMessage);
          })
          .catch((e) => {
            this.loading = false;
           
            let errorTitle = "Submit Failed";
            let errorMessage = e.response?.data?.message;
            this.showErrorAlert(errorTitle, errorMessage);
          });
      } else {
        this.Api.post(`/merchant/${this.merchantId}/payoutdetail`, this.formPayout)
          .then((res) => {
            this.loading = false;
            this.refresh();
            this.clearDataMerchant();
            $("#payoutSettingModal").modal("hide");

            let successTitle = "Submit Success";
            let successMessage = "Data payment setting tersimpan";
            this.showSuccessAlert(successTitle, successMessage);
          })
          .catch((e) => {
            this.loading = false;
           
            let errorTitle = "Submit Failed";
            let errorMessage = e.response?.data?.message;
            this.showErrorAlert(errorTitle, errorMessage);
          });
      }
    },
    showErrorAlert(errorTitle, errorMessage) {
      alert(`${errorTitle}, ${errorMessage}`)
    },
    showSuccessAlert(successTitle, successMessage) {
      alert(`${successTitle}, ${successMessage}`)
    },
  },
};
</script>

<template>
    <!-- modal -->
    <div>
        <div class="modal fade" id="payoutSettingModal" tabindex="-1" role="dialog" aria-labelledby="payoutSettingModalLabel"
            aria-hidden="true">
            <div class="modal-dialog" role="document" style="max-width:794px;">
                <form @submit.prevent="savePaymentSetting" class="card">
                    <div class="modal-content">
                        <div class="modal-header bg-primary">
                            <h5 class="modal-title" id="payoutSettingModalLabel"> <img src="../../../public/sensor.png"> Payment Gateway Setting</h5>
                        </div>
                        <div class="modal-body">
                           <div>
                                <img :src="merchants.image" width="100" style="border-radius: 50%;">
                                <span> {{ merchants.name }}</span>  
                           </div>
                            <div class="form-group">
                                <label for="payout-method" class="col-form-label">Choose Payment Gateway</label>
                                <select2 v-model="formPayout.payout_method.id" :config="paymentGatewayConfig" :disabled="loading" class="form-control">
                                    <option :value="formPayout.payout_method.id">{{ formPayout.payout_method.name }}</option>
                                </select2>
                            </div>
                            <div class="row">
                                <div class="col"> 
                                    <div class="form-group">
                                        <label class="col-form-label" for="api-key">API Key</label>
                                        <input id="api-key" v-model="formPayout.api_key" class="form-control"
                                               placeholder="API Key" type="text"
                                            :disabled="loading">
                                    </div>
                                </div>
                                <div class="col"> 
                                    <div class="form-group">
                                        <label class="col-form-label" for="api-key-2">API Key 2</label>
                                        <input id="api-key-2" v-model="formPayout.api_key_2" class="form-control"
                                               placeholder="API Key 2" type="text"
                                            :disabled="loading">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label class="col-form-label" for="secret-key">Secret Key</label>
                                        <input id="secret-key" v-model="formPayout.secret_key" :disabled="loading"
                                               class="form-control" placeholder="Secret Key"
                                               type="text">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label class="col-form-label" for="callback-token">Callback Token</label>
                                        <input id="callback-token" v-model="formPayout.callback_token" :disabled="loading"
                                               class="form-control" placeholder="Callback Token"
                                               type="text">
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label class="col-form-label" for="no-merchant-id">No. Merchant ID</label>
                                        <input id="no-merchant-id" v-model="formPayout.no_merchant_id" :disabled="loading"
                                               class="form-control" placeholder="No. Merchant ID"
                                               type="text">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label class="col-form-label" for="tenant-name">Tenant Name</label>
                                        <input id="tenant-name" v-model="formPayout.tenant_name" :disabled="loading"
                                               class="form-control" placeholder="Tenant Name"
                                               type="text">
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label class="col-form-label" for="tenant-code">Tenant Code</label>
                                        <input id="tenant-code" v-model="formPayout.tenant_code" :disabled="loading"
                                               class="form-control" placeholder="Tenant Code"
                                               type="text">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label class="col-form-label" for="username">Username</label>
                                        <input id="username" v-model="formPayout.username" :disabled="loading"
                                               class="form-control" placeholder="Username"
                                               type="text">
                                    </div>
                                </div>
                                <div class="col"> 
                                    <div class="form-group">
                                        <label for="password" class="col-form-label">Password</label>
                                        <input id="password" v-model="formPayout.password" class="form-control"
                                               placeholder="Password" type="text"
                                            :disabled="loading">
                                    </div>
                                </div>   
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label class="col-form-label" for="partner-id">Partner Id</label>
                                        <input id="partner-id" v-model="formPayout.partner_id" :disabled="loading"
                                               class="form-control" placeholder="Partner ID"
                                               type="text">
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label class="col-form-label" for="sub-partner-id">Sub Partner Id</label>
                                        <input id="sub-partner-id" v-model="formPayout.sub_partner_id" :disabled="loading"
                                               class="form-control" placeholder="Sub Partner ID"
                                               type="text">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col"> 
                                    <div class="form-group">
                                        <label for="pos-id" class="col-form-label">Pos ID</label>
                                        <input type="text" class="form-control" id="pos-id" v-model="formPayout.pos_id" placeholder="Pos ID"
                                            :disabled="loading">
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label for="store-id" class="col-form-label">Store ID</label>
                                        <input type="text" class="form-control" id="store-id" v-model="formPayout.store_id" placeholder="Store ID"
                                            :disabled="loading">
                                    </div>
                                </div>   
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label class="col-form-label" for="project-id">Project ID</label>
                                        <input id="project-id" v-model="formPayout.project_id" :disabled="loading"
                                               class="form-control" placeholder="Project ID"
                                               type="text">
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label class="col-form-label" for="collection-name">Collection Name</label>
                                        <input id="collection-name" v-model="formPayout.collection_name" :disabled="loading"
                                               class="form-control" placeholder="Collection Name"
                                               type="text">
                                    </div>
                                </div>
                            </div>    
                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label class="col-form-label" for="private-key-json">Private Key Json</label>
                                        <textarea id="private-key-json" v-model="formPayout.private_key_json" :disabled="loading"
                                               class="form-control" placeholder="Private Key Json"
                                               type="text"/>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label class="col-form-label" for="private-key-base64">Private Key Base64</label>
                                        <textarea id="private-key-base64" v-model="formPayout.private_key_base64" :disabled="loading"
                                                  class="form-control" placeholder="Private Key Base64"
                                                  type="text"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                            <!-- </div> -->
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="close">Close</button>
                                <button type="submit" class="btn btn-primary" :disabled="loading">
                                    <i class="fas fa-spinner fa-spin" v-if="loading"></i>
                                    {{ loading ? 'Saving ...' : 'Save Setting' }}
                                </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

</template>

<script>

export default {
    props: ["merchant", "paymentGatewayConfig", "close", "savePaymentSetting", "formPayout"],
    computed: {
        console: () => console,
    },
    data() {
        return {
            merchants: this.merchant,
            active: false,
            errorDialog: null,
            errorText: '',
            error: undefined,
            loading: false,
            text_validation : null,
            maxSize: 1024,
            selectedPaymentGateway: null,
            // form : {
            //     id: null,
            //     api_key: "",
            //     callback_token: "",
            //     username: "",
            //     password: "",
            //     no_merchant_id: "",
            //     store_id: "",
            //     pos_id: "",
            //     payout_method: {
            //         id: ""
            //     },
            //     merchant: {
            //         id: ""
            //     }
            // }, 
            disabled: false,
        }
    },
}

</script>